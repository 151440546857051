@use '../../../sass/mixins' as *;
@use '../../../sass/variables' as *;
@use '../../../sass/breakpoints' as *;

.audience-room {
  width: 100%;

  // Room model CSS
  .room-model-hidden {
    @include flex-center-center-column;
    padding: $base-padding;
    display: none;

    h2 {
      font-size: 1.25em;
      margin: $base-margin;
    }

    .room-model {
      display: grid;
      grid-template: repeat(2, 70px) / repeat(6, 40px);
      gap: 8px;

      div {
        @include flex-center-center-column;
        background-color: $quinary;
      }

      .stage-model {
        grid-area: 1 / 3 / 2 / 5;
        border-radius: 50%;
      }

      .sector-model-A {
        grid-area: 1 / 1 / 2 / 3;
      }

      .sector-model-B {
        grid-area: 1 / 5 / 2 / 7;
      }

      .sector-model-C {
        grid-area: 2 / 2 / 3 / 4;
      }

      .sector-model-D {
        grid-area: 2 / 4 / 3 / 6;
      }
    }
  }


  // Room Layout CSS
  .container {
    @include container;
    display: grid;
    grid-template: 1fr 1fr / repeat(6, 0.5fr);
    padding: $base-padding*2;
    column-gap: 5%;
    border: 2px solid;
    border-image: linear-gradient(90deg, $gradient-color-one, $gradient-color-two) 1;

    .stage {
      @include flex-center-center-row;
      background-color: $quaternary;
      grid-area: 1 / 3 / 2 / 5;
      border-radius: 50%;
      margin: $base-margin*4 0;
      
      h1 {
        color: $primary;
      }
    }

    .sector {
      display: grid;
      grid-template: repeat(8, 1fr) / repeat(7, 1fr);
    }

    .sector-name {
      grid-column: 1 / 8;
    }

    .sector-A {
      grid-area: 1 / 1 / 2 / 3;
      grid-auto-flow: column;
    }

    .sector-B {
      grid-area: 1 / 5 / 2 / 7;
      grid-auto-flow: column;
    }

    .sector-C {
      grid-area: 2 / 2 / 3 / 4;
    }

    .sector-D {
      grid-area: 2 / 4 / 3 / 6;
    }
  }
}

// Media queries

@include mobile {
  .audience-room {
    .room-model-hidden{
      display: flex;
    }

    .container {
      grid-template: repeat(4, 1fr) / 1fr;
      column-gap: 0;
      padding: $base-padding;

      .stage {
        display: none;
      }

      .sector-A {
        grid-area: 1 / 1 / 2 / 2;
        grid-auto-flow: column;
      }

      .sector-B {
        grid-area: 2 / 1 / 3 / 2;
        grid-auto-flow: column;
      }

      .sector-C {
        grid-area: 3 / 1 / 4 / 2;
      }

      .sector-D {
        grid-area: 4 / 1 / 5 / 2;
      }
    }
  }
}

@include tablet {
  .audience-room {
    .room-model-hidden {
      display: flex;
      
      h2 {
        font-size: 1.5em;
      }

      .room-model{
        font-size: 1.25em;
      }
    }

    .container {
      grid-template: repeat(2, auto) / repeat(2, auto);
      padding: $base-padding*1.5;

      .stage {
        display: none;
      }

      .sector-A {
        grid-area: 1 / 1 / 2 / 2;
        grid-auto-flow: column;
      }

      .sector-B {
        grid-area: 1 / 2 / 2 / 3;
        grid-auto-flow: column;
      }

      .sector-C {
        grid-area: 2 / 1 / 3 / 2;
      }

      .sector-D {
        grid-area: 2 / 2 / 3 / 2;
      }
    }
  }
}