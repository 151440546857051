@use "../../sass/mixins" as *;
@use "../../sass/variables" as *;
@use "../../sass/breakpoints" as *;
@use "sass:math";

.checkout {
  @include container;
  display: grid;
  grid-template: auto / 1fr 1fr;
  border-left: 1px solid;
  border-right: 1px solid;
  border-image: linear-gradient(90deg, $gradient-color-one, $gradient-color-two) 1;

  > * {
    text-align: center;
  }

  .summary-heading {
    grid-area: 1 / 1 / 2 / 2;
    margin: $base-margin;
  }

  ul {
    list-style: none;
    grid-area: 2 / 1 / 3 / 2;
    color: $quaternary;
    padding: 0;
    margin: 0;

    li {
      h2, h4 {
        margin: math.div($base-margin, 2);
      }

      h2 {
        display: inline-block;
        background-clip: text;
        color: transparent;
        background-image: linear-gradient(90deg, $gradient-color-one, $gradient-color-two);
      }
    }
  }

  .form-heading {
    grid-area: 1 / 2 / 2 / 3;
    margin: $base-margin;
  }

  form {
    grid-area: 2 / 2 / 3 / 3;

    .payment-simulation-tips {
      display: grid;
      grid-template: auto / 1fr 1fr;

      div {
        @include flex-center-center-column
      }

      span {
        font-weight: bold;
      }

      small {
        color: white;
      }
    }
  }

  button {
    @include gradient-button;
  }
}

// Media querries

@include mobile {
  .checkout {
    grid-template: repeat(2, 0.1fr auto) / auto;

    .summary-heading {
      grid-area: 1 / 1 / 2 / 2;
      font-size: 1.75em;
    }

    ul {
      grid-area: 2 / 1 / 3 / 2;

      h3 {
        font-size: 1em;
      }
    }

    .form-heading {
      grid-area: 3 / 1 / 4 / 2;
      font-size: 1.75em;
    }

    form {
      grid-area: 4 / 1 / 5 / 2;

      .payment-simulation-tips {
        grid-template: 1fr 1fr / auto
      }
    }
  }
}
