@use './sass//variables' as *;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  overflow-x: hidden;
}

body {
  font-family: $base-font-family, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: $primary;
  overflow-x: hidden;
}