@use '../../../sass/mixins' as *;
@use '../../../sass/variables' as *;
@use '../../../sass/breakpoints' as *;

.classic-room {
  width: 100%;

  // Room model CSS
  .room-model-hidden {
    @include flex-center-center-column;
    padding: $base-padding;
    display: none;

    h2 {
      font-size: 1.25em;
      margin: $base-margin;
    }

    .room-model {
      display: grid;
      grid-template: 50px 100px / 100px 100px;
      row-gap: 8px;
      column-gap: 10px;

      div {
        @include flex-center-center-row;
        background-color: $quinary;
      }

      .stage-model {
        grid-area: 1 / 1 / 2 / 3;
      }

      .sector-model-A {
        grid-area: 2 / 1 / 3 / 2;
      }

      .sector-model-B {
        grid-area: 2 / 2 / 3 / 3;
      }
    }
  }

  // Room Layout CSS
  .container {
    @include container;
    display: grid;
    grid-template: 0.75fr 3fr / 1fr 1fr;
    column-gap: 10%;
    padding: $base-padding*2;
    border: 2px solid;
    border-image: linear-gradient(90deg, $gradient-color-one, $gradient-color-two) 1;

    .stage {
      @include flex-center-center-row;
      background-color: $quaternary;
      grid-area: 1 / 1 / 2 / 3;
      
      h1 {
        color: $primary;
      }
    }

    .sector {
      display: grid;
      grid-template: repeat(11, 1fr) / repeat(10, 1fr);
    }

    .sector-name {
      grid-column: 1 / 11;
    }

    .sector-A {
      grid-area: 2 / 1 / 3 / 2;
    }

    .sector-B {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
}

// Media queries

@include mobile {
  .classic-room {
    .room-model-hidden {
      display: flex;
    }

    .container {
      grid-template: 1fr 1fr / 1fr;
      column-gap: 0;
      padding: $base-padding;

      .stage {
        display: none;
      }

      .sector-A {
        grid-area: 1 / 1 / 2 / 2;
      }
  
      .sector-B {
        grid-area: 2 / 1 / 2 / 3;
      }
    }
  }
}

@include tablet {
  .classic-room {
    .room-model-hidden {
      display: flex;

      h2 {
        font-size: 1.5em;
      }

      .room-model{
        font-size: 1.25em;
      }
    }

    .container {
      grid-template: auto / repeat(2, auto) ;
      column-gap: 5%;
      padding: $base-padding*1.5;

      .stage {
        display: none;
      }

      .sector-A {
        grid-area: 1 / 1 / 2 / 2;
      }
  
      .sector-B {
        grid-area: 1 / 2 / 2 / 3;
      }
    }
  }
}