@use '../../sass/variables' as *;
@use '../../sass/mixins' as *;
@use '../../sass/breakpoints' as *;

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
}

.overlay {
  background: rgba(49,49,49,0.8);
}

.modal-content {
  z-index: 2;
  @include flex-center-center-column;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: $primary;
  border: solid 2px;
  border-image: linear-gradient(90deg, $gradient-color-one, $gradient-color-two)1;
  padding: $base-padding*2 $base-padding*3;
  max-width: 600px;
  min-width: 400px;

  h2 {
    color: $quaternary;
    text-align: center;
  }

  button {
    @include gradient-button;
  }
}

// Media queries

@include mobile {
  .modal-content {
    height: 150px;
    min-width: 250px;

    h2 {
      font-size: 0.75em;
      max-width: 100%;
    }

    button {
      font-size: 0.75em;
    }
  }
}

@include tablet {
  .modal-content {
    h2 {
      font-size: 1em;
    }

    button {
      font-size: 0.75em;
    }
  }
}