@use '../../sass/variables' as *;
@use '../../sass/mixins' as *;
@use '../../sass/breakpoints' as *;

.about {
  @include gradient-background-horizontal;
  width: 100%;

  .about-container{
    @include container;

    article {
      margin: 0;
      padding-bottom: $base-padding*3;
      color: $tertiary;
    }
  }
}

@include mobile {
  .about-container{
    text-align: center;
    
    h2 {
      font-size: 1.25em;
    }
    article {
      font-size: 0.75em;
    }
  }
}
@include tablet {
  .about-container{
    text-align: center;
  }
}