$breakpoints: (
  "mobile": 480px,
  "tablet": 767px,
);

@mixin mobile {
  @media (max-width: map-get($breakpoints, "mobile")) {
    @content;
  };
};
@mixin tablet {
  @media (min-width: map-get($breakpoints, "mobile")) and (max-width: map-get($breakpoints, "tablet")) {
    @content;
  };
};