@use '../../sass/mixins' as *;
@use '../../sass/variables' as *;
@use '../../sass/breakpoints' as *;

.navbar {
  width: 100%;
  background-image: linear-gradient(90deg, $gradient-color-one, $gradient-color-two);

  ul {
    @include container;
    @include flex-center-center-row;
    list-style: none;
  }

  li {
    padding: $base-padding;
    text-align: center;

    a {
      text-decoration: none;
      color: $quaternary;

      &.active {
        font-weight: bold;
      }
    }
  }

  .logo {
    margin-right: auto;
    font-size: 2em;
    font-weight: bold;
  }
}

// Media breakpoints

@include mobile {
  .navbar {
    ul {
      display: grid;
      grid-template: 1fr 1fr / 1fr 1fr 1fr;

      li {
        font-size: 0.75em;
        padding: 0;
      }

      .logo {
        margin: auto;
        grid-column: span 3;
      }
    }
  } 
}
@include tablet {
  .navbar {
    ul {
      display: grid;
      grid-template: auto / 1fr 1fr 1fr;
      padding: $base-padding;

      li {
        font-size: 1em;
      }

      .logo {
        margin: auto;
        grid-column: span 3;
      }
    }
  } 
}