// Colors
$primary: black;
$secondary: #1C1C1C;
$tertiary: #A9A19B;
$quaternary: white;
$quinary: #888888;

// Border and button gradient colors
$gradient-color-one: red;
$gradient-color-two: blue;

// Padding and margin
$base-padding: 0.5rem;
$base-margin: 0.5rem;

// Font family
$base-font-family: Poppins;